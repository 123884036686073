import Service from "../Service";
const resource = "disinfectedpallet/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },


    paginationselection(requestID) {
        return Service.post(resource + "paginationselection", {}, {
            params: { requestID: requestID },
        });
    },

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    saveselection(obj, requestID) {
        return Service.post(resource + "saveselection", obj, {
            params: { requestID: requestID },
        });
    },


    reportpdf(dtr, requestID) {
        return Service.post(resource + "reportpdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

};