import Service from "../Service";

const resource = "/palletprocess/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID }
        })
    },


    list(filter, requestID) {
        return Service.post(resource + "list", filter, {
            params: { requestID }
        })
    },

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID }
        })
    },

    printqr(obj, requestID) {
        return Service.post(resource + "printqr", obj, {
            params: { requestID }
        })
    },

    search(obj, requestID) {
        return Service.post(resource + "search", obj, {
            params: { requestID }
        })
    }

}