import Service from "../Service";
const resource = "/lotproductionselectionpalletsend/";

export default {

    send(obj, requestID) {
        return Service.post(resource + "send", obj, {
            params: { requestID: requestID },
        });
    },

    update(obj, requestID) {
        return Service.post(resource + "update", obj, {
            params: { requestID: requestID },
        });
    },


    pagination(obj, requestID) {
        return Service.post(resource + "paginationPrintQR", obj, {
            params: { requestID: requestID },
        });
    },
    paginationlist(obj, requestID) {
        return Service.post(resource + "paginationlistPrintQR", obj, {
            params: { requestID: requestID },
        });
    },

    GetDetailQR(LlpID, requestID) {
        return Service.post(resource + "GetDetailQR", "", {
            params: { LlpID: LlpID, requestID: requestID },
        });
    },

    GetDetailsAllQR(LlpID, requestID) {
        return Service.post(resource + "GetDetailsAllQR", "", {
            params: { LlpID: LlpID, requestID: requestID },
        });
    },


    getlotproductionselectionpalletsend(obj, requestID) {
        //PrsID: PrsID, 
        return Service.post(resource + "getlotproductionselectionpalletsend", obj, {
            params: { requestID: requestID },
        });
    },

    transfer(obj, requestID) {
        return Service.post(resource + "transferParihuela", obj, {
            params: { requestID: requestID },
        });
    },

    GetKgSelection(obj, requestID) {
        return Service.post(resource + "GetKgSelection", obj, {
            params: { requestID: requestID },
        });
    },
    GetKgSelectionDecrease(obj, requestID) {
        return Service.post(resource + "GetKgSelectionDecrease", obj, {
            params: { requestID: requestID },
        });
    },


    listpalletpartjoin(requestID) {
        return Service.post(resource + "listpalletpartjoin", {}, {
            params: { requestID }
        });
    },

    trackinginformation(LlpID, requestID) {
        return Service.post(resource + "trackinginformation", {}, {
            params: { LlpID, requestID }
        });
    },

    findlocation(LlpID, requestID) {
        return Service.post(resource + "findlocation", {}, {
            params: { LlpID, requestID }
        });
    },

    searchbyid(LlpID, requestID) {
        return Service.post(resource + "searchbyid", {}, {
            params: { LlpID, requestID }
        });
    },

    cancelmaturation(obj, requestID) {
        return Service.post(resource + "cancelmaturation", obj, {
            params: { obj, requestID }
        });
    },

};