<template>
	<v-card class="m-0 p-0">
		<s-toolbar
			label="Enviar lavado y desinfección"
			dark
			:color="'#BAB6B5'"
			close
			@close="closeModal"
			save
			@save="save"
		></s-toolbar>
		<v-container>
			<v-row style="margin:auto">
				<!-- <v-col cols="12" lg="6" md="6" >
					<s-select-sampling-location
						full
						:TypeCultive="TypeCultive"
						v-model="item.SplID"
						label="Lugar Muestra"
					></s-select-sampling-location>
				</v-col> -->
				<v-col>
					<!-- <s-time
						label="Hora"
						v-model="item.DspHour"
					></s-time> -->
					<s-date-time
						label="Fecha y Hora"
						v-model="item.DspHour"
					></s-date-time>
				</v-col>
				<v-col cols="12" lg="4" md="4" >
					<s-select-definition
						v-model="item.TypeDisinfectant"
						:def="1329"
						label="Tipo desinfectante"
					></s-select-definition>
				</v-col>
				<v-col cols="12" lg="4" md="4">
					<s-select
						label="Lugar de muestra"
						full
						item-value="SlsID"
						item-text="SlsName"
						:items="itemsSamplingLocationSelection"
						v-model="item.SlsID"
					></s-select>
				</v-col>
				

				<v-col v-if="item.TypeDisinfectant == 3" cols="12" lg="4" md="4" >
					<s-text
						v-model="item.DspOtherDisinfectant"
						label="Otro Desinfectante"
						decimal
					></s-text>
				</v-col>

				<v-col cols="12" lg="4" md="4" >
					<s-text
						v-model="item.DspInitialConcentration"
						label="Concentracion inicial (ppm)"
						decimal
					></s-text>
				</v-col>
				<v-col cols="12" lg="4" md="4">
					<s-text
						v-model="item.DspRepowering"
						label="Repotenciación (ml)"
						decimal
					></s-text>
				</v-col>
				<v-col cols="12" lg="4" md="4" >
					<s-text
						v-model="item.DspFinalConcentration"
						label="Concentración final (ppm)"
						decimal
					></s-text>
				</v-col>
				<v-col cols="12" lg="4" md="4" >
					<s-text
						v-model="item.DspDiveTime"
						label="Tiempo de inmersión (min)"
						decimal
					></s-text>
				</v-col>
				<v-col cols="12" lg="4" md="4" >
					<s-select-definition
						v-model="item.TypeCorrelativeAction"
						:def="1330"
						label="Acción correctiva/Observaciones"
					></s-select-definition>
				</v-col>
				<v-col cols="12" lg="4" md="4" >
					<s-select-definition
						v-model="item.TypeWaterLevel"
						:def="1331"
						label="Nivel de agua en tina"
					></s-select-definition>
				</v-col>
				<v-col cols="12" lg="12" md="12" >
					<s-textarea
						v-model="item.DspObservation"
						label="Observaciones"
					></s-textarea>
				</v-col>
			</v-row>
			
		</v-container>
	</v-card>
</template>

<script>
	import SSelectSamplingLocation from '../../../components/FrozenProduction/WashDisinfect/SSelectSamplingLocation.vue';
	import _sDisinfectedPalletService from "@/services/FrozenProduction/DisinfectedPalletService"
	import _sSamplingLocationSelectionService from "@/services/FrozenProduction/SamplingLocationSelectionService";

	export default {
		components: { 
			SSelectSamplingLocation 
		},

		props: {
			TypeCultive: {
				type: Number,
				default: 0
			},
			pallet: null,
		},

		data() {
			return {
				item: {},
				itemsSamplingLocationSelection: [],
			}
		},

		methods: {
			closeModal() {
				this.$emit("closeModalDisinfected");
			},

			save()
			{
				console.log('savee ', this.pallet);
				this.item.LlpID = this.pallet.LlpID;
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();
				this.item.UsrUpdateID	= this.$fun.getUserID();
				this.$fun.alert("¿Seguro de pasar a lavado desinfección?", "question")
				.then(r => {
					if(r.value){
						console.log("send  ", this.item);
						_sDisinfectedPalletService
						.saveselection(this.item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Pallet pasado a desinfección correctamente", "success");
								this.$emit("closeModalDisinfected");
							}
						})
					}
				})
				
			},

			getSamplingLocationSelection()
			{
				let rowSelect = {
					TypeCultive: this.TypeCultive
				}
				console.log('sssssssssssssssss', rowSelect)
				_sSamplingLocationSelectionService
				.list(rowSelect, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsSamplingLocationSelection =  resp.data;
					}
				})
			},

		},

		created () {
			this.item = this.pallet;
			this.getSamplingLocationSelection();
			
		},
	}
</script>