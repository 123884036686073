<template>
  <div>
    <v-card>
      <v-container  >
        <v-row>
          <div class="col-md-12 col-sm-12">
            <v-card dense>
              <v-card-text>
                <b>Control de Ingreso de Materia Prima a Proceso</b>
              </v-card-text>
              <v-container>
                <v-row style="margin: auto">
                  <v-col cols="12">
                    <s-scanner-qr
                      :config="configScann"
                      autofocus
                      return-object
                      :readonly="false"
                      @onValue="onValue($event)"
                    ></s-scanner-qr>
                  </v-col>
                  <v-col cols="6" hidden>
                      <s-select-definition
                        label="Tipo parihuela"											 
                        :def="1363"
                        return-object											 
                        @change="changeTypePallet($event)"
                        >
                      </s-select-definition>
                    </v-col>
                        
                    <v-col hidden>
                      <s-select-definition
                        @getDataInitial="getDataInitial($event)"
                        label="Proceso"
                        :def="1231"
                      >
                      </s-select-definition>
                    </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <!--  <v-row>
              <v-col>
                <s-toolbar
                  dark
                  :color="'#BAB6B5'"
                  class="mr-4 ml-2"
                  style="display: flex"
                  print
                  @print="printQR()"
                ></s-toolbar>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <s-toolbar
                  dark
                  :color="'#BAB6B5'"
                  class="mr-4 ml-2"
                  style="display: flex"
                  label="Listado de pallets ingresados a proceso"
                ></s-toolbar>
              </v-col>
              <v-col>
                <v-container> 
                <v-row  >
                  <v-col cols="3">
                    <s-date 
                      label="Fecha Inicio"
                      v-model="filter.DateBegin" 
                      @input="getPalletProcess()" 
                    />
                  </v-col>
                  <v-col cols="3">
                    <s-date 
                      label="Fecha Fin"
                      v-model="filter.DateEnd"  
                      @input="getPalletProcess()" 
                    />
                  </v-col>
                  <v-col cols="3">
                    <s-select-definition 
                      label="Turno"
                      v-model="filter.TypeTurn"  
                      :def="1278"
                      
                      @change="getPalletProcess()" 
                      clearable
                    />
                      <!-- :dgr="53" -->
                  </v-col>
                </v-row> 
                </v-container>
              </v-col>
              <v-col cols="12">
                
                <v-data-table
                  :items="palletsprocess"
                  item-key="LlpID"
                  dense
                  :headers="headers"
                  :items-per-page="15"
                  disable-sort
                  v-model="selected"
                  no-full
                  search-input
                  @click:row="rowClickSend"
                  :search="search"
                >
                <template v-slot:top>
                  <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                  ></v-text-field>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
            
            
            <v-card>
              <v-row justify="center" cols="12" style="display: none">
                <v-col cols="12" v-for="item in selected">
                  
                  <qr-code
                    :text="
                      item.PprID +
                        ',' +
                        item.LlpID +
                        ',' +
                        item.TypeProcess +
                        ',' +
                        item.PprOrigin +
                        ',' +
                        item.PprOriginID +
                        ',' +
                        item.PprQuantityJaba
                    "
                    error-level="H"
                    :size="500"
                    hidden
                  >
                  </qr-code>
                  <br />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <!-- <v-row>
          <v-col cols="12">
            <s-crud
              :config="config"
              title="Pallet para proceso"
              ref="crudpallets"
              height="auto"
            >
              <template v-slot:accion="{row}">
                <v-btn
                  color="success"
                  x-small
                  @click="openModal(row)"
                >
                  <i class="fas fa-play"></i>
                </v-btn>
              </template>
            </s-crud>
          </v-col>
        </v-row> -->
      </v-container>

      <v-dialog
        v-model="openDialog"
        v-if="openDialog"
        persistent
        transition="dialog-bottom-transition"
        width="700"
      >
        <create-disinfected-pallet
          @closeModal="closeModal"
          :TypeCultive="TypeCultive"
          :pallet="pallet"
        ></create-disinfected-pallet>
      </v-dialog>



      <v-dialog
          v-model="viewModalSendProccess"
          persistent
          width="800"
          v-if="viewModalSendProccess"
        >
          <v-card>
            <v-container>
              <v-row>
                <s-toolbar
                  dark
                  
                  color="#7b7b7b" 
                  :label="this.palletProcess.LlpID > 0 ? 'Envio a Proceso del Pallet ' + this.palletProcess.LlpID : 'Envio a Proceso '"
                  close
                  @close="viewModalSendProccess = false"
                  save
                  @save="sendProcess()"
                >

                </s-toolbar>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <!-- <sselect-client-sap
                    v-model="palletProcess.CustomerID"
                    label="Cliente"
                  ></sselect-client-sap> -->
                  <s-text 
                    label="Cliente"
                    v-model="palletProcess.CumNombre"
                    readonly>
                  </s-text>
                </v-col>
                <v-col cols="3">
                  <s-date 
											v-model="palletProcess.FfsDateSampling"
											label="Fecha"></s-date>
                </v-col>
                <v-col cols="3">
                  <!--jmr
                  v-model="StarDate"

                  palletProcess.FfsDateSampling
                  -->
                  <!-- <s-text
                      label="Hora de muestreo"
                      v-model="PprHour"
                      type="time"
                  ></s-text> -->
                  <label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora de ingreso a proceso</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="PprHour">
                      </datetime>
									</div>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="2" v-if="edit">
                  <v-checkbox label="Editar Peso" @change="EditWeight()">

                  </v-checkbox>
                </v-col>
              <!-- </v-row>
              <v-row>                 -->
                <v-col cols="3">
                  <s-text
                    v-model="palletProcess.PprWeightGross"
                    decimal
                    label="Peso Bruto"
                    @input="inputWeightGross($event)"
                  ></s-text>
                </v-col>
                <v-col cols="3">
                  <s-text
                    v-model="palletProcess.PprWeightJaba"
                    decimal
                    label="Peso Jaba"
                    readonly
                  ></s-text>
                </v-col>
                <v-col cols="3">
                  <s-text
                    v-model="PprWeightNet"
                    decimal
                    label="Peso a proceso"
                    readonly
                  ></s-text>
                </v-col>     
                
                           
              </v-row>
              
              <v-row>
                <v-col cols="6">
                  <s-select-definition
                    label="Tipo parihuela"
                    v-model="TypePallet"
                    :def="1363"
                    return-object
                    @input="selectTypePallet($event)">
                  </s-select-definition>
                </v-col>
                <v-col cols="6">
                  <s-select-definition
                    v-model="palletProcess.TypeProcess"
                    label="Tipo de IQF"
                    :def="1340"
                  >
                  </s-select-definition>
                </v-col>   
                <v-col cols="6">
                  <s-select-definition 
                    label="Turno"
                    v-model ="palletProcess.TypeTurn"
                
                    :def="1278">

                  </s-select-definition>
                </v-col>             
                <v-col cols="6">
                  <s-select
                    label="Medio Ingreso"
                    full
                    item-value="SplID"
                    item-text="SplName"
                    :items="samplingLocation"
                    v-model="palletProcess.SplID"
                  ></s-select>
                </v-col>
                <v-col cols="6">
                  <s-select-definition
                    label="Presentacion"
                    v-model="IdPresentation"
                    full       
                    return-object             
                    :def="1341"
                    add
                  ></s-select-definition>
                </v-col>
                <!-- agregamos el tipo de parihuela samir-->
                      
                
                
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

      <!--  <v-dialog v-model="dialogDetail" width="500">
        <v-card>
          <s-toolbar
            label="Detalle"
            dark
            :color="'#BAB6B5'"
            close
            @close="dialogDetail = false"
          ></s-toolbar>
          <v-container>
            <v-row style="margin: auto">
              <v-col cols="12" lg="6" md="6">
                <s-text
                  label="Tipo Selección"
                  readonly
                  v-model="detail.TypeSelectionFrozenName"
                ></s-text>
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <s-text
                  label="Categoría"
                  readonly
                  v-model="detail.TypeFrozenCategoryName"
                ></s-text>
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <s-text
                  label="Tipo Cultivo"
                  readonly
                  v-model="detail.TypeCropName"
                ></s-text>
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <s-text
                  label="Cultivo"
                  readonly
                  v-model="detail.TypeCultiveName"
                ></s-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog> -->
    </v-card>  
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';

import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService.js";
import _sPalletProcessService from "@/services/FrozenProduction/PalletProcessService.js";
import CreateDisinfectedPallet from "./CreateDisinfectedPallet.vue";
import SselectClientSap from '../../../components/Sale/SselectClientSap.vue';
import _sPalletSend from '@/services/FrozenProduction/FrzLotProductionSelectionPalletSendService.js';
import _sSamplingLocationService from '@/services/FrozenProduction/SamplingLocationService.js';
import _sParameter from "@/services/General/ParameterService.js";
import qrCode from "vue-qr-generator";
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';


export default {
  components: {
    CreateDisinfectedPallet,
    SselectClientSap,
    qrCode,
    SSelectDefinition,
    Datetime
  },

  data() {
    return {
      search: "",
      PprWeightNet: 0,
      TypePalletItems:[],
      TypePallet: 0,
      IdPresentation: 0,
      TypePalletHelper: 0,
      samplingLocation: [],
      viewModalSendProccess:false,
      palletProcess: {},
      openDialog: false,
      dialogWeigh: false,
      BrixGrade: false,
      filter: {
        // FtmID: 0,
        // TypeMaturation: 0,
        // MtpStatus: 2,
        DateBegin: "",
        DateEnd: "",
        TypeTurn: ""
      },
      pallets: [],
      pallet: {},
      TypeCultive: 0,
      dialogDetail: false,
      detail: [],
      configScann: {},
      config:{
        model:{
          PprID: "ID",
          accion: "string"
        },
        service: _sPalletProcessService,        
      },
      selected: [],
      palletsprocess: [],
      itemsDetail: [],
      headers: [
          //{text: "Hora Standar", value: ""},
          {text: "ID Pallet sunexpert", value: "LlpID", width: 100},
          {text: "Hora Proceso", value: "PprHourString", width: 100},
          {text: "Numero de Palleticket", value: "NumberPalletTicket", width: 100},
          {text: "Lote de Materia Prima", value: "NumberYuliano", width: 100},
          {text: "# de Jabas", value: "QuantityJaba", width: 100},
          {text: "Tamaño de Fruta", value: "TypeFruitName", width: 100},
          {text: "Tipo de Maduración", value: "TypeMaturationName", width: 100},
          // {text: "PH", value: "Ph", width: 100},
          // {text: "% LMF", value: "LMF", width: 100},
          {text: "Cliente", value: "TypeClienteName", width: 100},
          {text: "Presentación", value: "Presentation", width: 100},
          {text: "Tipo de Proceso", value: "TypeProccesNameS", width: 70},
          {text: "Turno", value: "TypeTurnName", width: 80},
          {text: "Firmeza Promedio", value: "TypeAverageFirmness",  width: 80},
          {text: "Peso Lavado", value: "WeightWashed", width: 100},
          {text: "Peso a proceso", value: "WeightProccess", width: 100},
          {text: "Merma", value: "WeightMerma", width: 100},
          {text: "Brix Promedio", value: "BrixAverage", width: 80},
          {text: "Color", value: "TypeColorName", width: 70},
				],
      PprHour: "",
      StartDate: "time2",
      edit: false,
    };
  },
  watch : {
    filter(){
      this.getPalletProcess();
    },
     palletProcess: () => {
     }
   
  },

  methods: {
    EditWeight()
    {
      this.edit = !this.edit;
    },
    getPalletProcess()
			{
        
      _sPalletProcessService
      .list(this.filter,this.$fun.getUserID())
      .then((resp) => {
        if (resp.status == 200) {
          this.palletsprocess = resp.data;
          this.palletsprocess.forEach( item => {
            item.CumNombre = item.TypeClienteName;
            
            // item.CumNombre = item.TypeClienteName;
          });        
        }
      });
    },

    printQR() {
      var imgAll = document.querySelector("img");
      console.log('imgAll ', imgAll);
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(
        "<html><head><scri" +
          "pt>function step1(){\n" +
          "setTimeout('step2()', 10);}\n" +
          "function step2(){window.print();window.close()}\n" +
          "</scri" +
          "pt><style  media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
          "<div class='col col-12 justify-center'>",
      );

      
      /* imgAll.forEach(element => { */
        pwa.document.write(
          "<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
            imgAll.currentSrc +
            "' /></div>",
        );

        pwa.document.write("<br><br><div ><table border='0'>");
        pwa.document.write(
              "<tr><td colspan='7'>Datos Generales: " + 
              "<tr>" +
              "<td>" + "<b>"+this.selected[0].LlpID + "</b>"+
              "</td><td> ||" + "<b>"+this.selected[0].TypeProcessName + "</b>"+
              "</td><td> ||" + "<b>"+this.selected[0].CustomerName + "</b>"+
              "</td><td> || " + "<b>"+this.selected[0].VrtName + "</b>"+
              "</td><td> || " + "<b>"+this.selected[0].TypeCropName + "</b>"+
              "</td><td> || " + "<b>"+this.selected[0].TypeSelectionFrozenName + "</b>"+
              "</td><td> || " + "<b>"+this.selected[0].TypeFrozenCategoryName + "</b>"+
              " </td></tr>",
        );
        pwa.document.write("</table></div>");

        this.itemsDetail.forEach(detail => {
          
          pwa.document.write("<br><br><div ><table border='0'>");

          pwa.document.write(
            "<tr><td>Pallet: </td><td>" + detail.LlpID + "</td></tr>" +
            "<tr><td>Cliente: </td><td>" + detail.CustomerName +"</td></tr>"
          );

          pwa.document.write(
            "<tr><td>Cantidad Jabas: </td><td>" + detail.PprQuantityJaba +"</td></tr>" +
            "<tr><td>Tipo proceso: </td><td>" + detail.TypeProcessName + "</td></tr>" +
            "<tr><td>Tipo Bruto: </td><td>" + detail.PprWeightGross + "</td></tr>" +
            "<tr><td>Peso Neto: </td><td>" + detail.PprWeightNet + "</td></tr>" +
            "<tr><td>Tipo Bruto: </td><td>" + detail.PprWeightGross + "</td></tr>" +
            "<tr><td>Tipo Selección: </td><td>" + detail.TypeSelectionFrozenName + "</td></tr>" +
            "<tr><td>Categoría: </td><td>" + detail.TypeFrozenCategoryName + "</td></tr>" +
            "<tr><td>Tipo Cultivo: </td><td>" + detail.TypeCropName + "</td></tr>" +
            "<tr><td>Tipo Cultivo: </td><td>" + detail.TypeCultiveName + "</td></tr>" +
            "<tr><td>Variedad: </td><td>" + detail.VrtName + "</td></tr>" 
          );

          pwa.document.write("</table></div>");
        });

      pwa.document.write("</div></body></html>");
      pwa.document.close();
    },

    rowClickSend(item, row) {
      this.edit = true;
      this.palletProcess = {};
      this.PprWeightNet = 0;
      this.selected = {};
      this.selected = [item];
      
      this.itemsDetail = this.selected;
      
      this.palletProcess = this.itemsDetail[0];     
      console.log(this.palletProcess);
      _sSamplingLocationService
          .list(this.palletProcess, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200)
            {
              this.samplingLocation = resp.data;
            }
          }) 
      this.PprWeightNet = this.palletProcess.WeightProccess;
      this.IdPresentation = this.palletProcess.IdPresentation;
      this.viewModalSendProccess = true;

    },

    inputWeightGross(weight)
    {   
      
      if(!this.edit)
      {     
        console.log(this.palletProcess.PprWeightGross ,' ', this.palletProcess.PprWeightJaba ,' ', this.palletProcess.PprQuantityJaba, this.palletProcess);
        if (this.TypePalletHelper != undefined) {
        this.PprWeightNet = this.palletProcess.PprWeightGross - (this.palletProcess.PprWeightJaba * this.palletProcess.PprQuantityJaba)
          - this.TypePalletHelper;
          if(this.PprWeightNet < 0){
            this.PprWeightNet = 0;
          }  
        }
      }
    },

    selectTypePallet(item){
				if (item != undefined) {
					
					this.TypePalletHelper = item.DedHelper;
					this.inputWeightGross(this.TypePalletHelper);
             //this.TypePallet = item;   
				} 
        
    },
    changeTypePallet(items){
      
    },
    sendProcess()
    {
      // if(this.palletProcess.CustomerID == undefined || this.palletProcess.CustomerID == null){
      //   this.$fun.alert("Ingrese cliente", "warning");
      //   return; 
      // }
      if(this.palletProcess.PprWeightNet === 0){
        this.$fun.alert("Ingrese peso", "warning");
        return;  
      }
      this.palletProcess.PprWeightNet =  this.PprWeightNet;
      this.palletProcess.TypePallet = this.TypePallet.DedValue;
      this.palletProcess.PprHour = this.PprHour;
      this.palletProcess.StartDate = this.StartDate;

      if(this.IdPresentation.DedValue != undefined) 
      {
        this.palletProcess.IdPresentation = this.IdPresentation.DedValue;        
      }else
      {
        this.palletProcess.IdPresentation = this.IdPresentation;
      }
      
      
      this.$fun.alert("¿Seguro de envíar a proceso?", "question")
      .then(r => {
        if(r.value){
          
          _sPalletProcessService
          .save(this.palletProcess, this.$fun.getUserID())
          .then((resp) => {
            if(resp.status == 200){
              this.$fun.alert("Pallet enviado a proceso correctamente", "success");
              this.viewModalSendProccess = false
              // this.$refs.crudpallets.refresh()
              //samir
              this.getPalletProcess();
              //this.closeDetail();
              //this.generatedPositions();
            }
          });
          
        }
      })
    },
   

    getDataInitial(data)
    {
      data.forEach(element => {
        if(element.DedValue == 1){
         // this.palletProcess.PprWeightJaba = element.DedHelper;
        }
      })
    },

    onValue(val) {
      
      if (val == "Sin datos" || isNaN(val)) {
        this.$fun.alert("El QR Leido no contiene datos", "warning");
      } else {
        _sPalletSend
        .findlocation(val, this.$fun.getUserID())
        .then(resp => {
          if(resp.data == null){
            this.$fun.alert("El pallet no esta ingresado en ambiente, tunel, cámara, californiano ó ya fue enviado a procesado", "error");
            return;
          }
          
          
          this.palletProcess.LlpID = val;
          this.palletProcess.SecStatus = 1;
          this.palletProcess.UsrCreateID = this.$fun.getUserID();
          this.palletProcess.TypeSelectionFrozen = resp.data.TypeSelectionFrozen;
          this.palletProcess.TypeFrozenCategory = resp.data.TypeFrozenCategory;
          this.palletProcess.PprQuantityJaba = resp.data.LlpQuantityJaba;
          this.palletProcess.TypeCrop = resp.data.TypeCrop;
          this.palletProcess.TypeCultive = resp.data.TypeCultive;
          this.palletProcess.VrtID = resp.data.VrtID;
          this.palletProcess.FtsID = resp.data.FtsID;
          this.palletProcess.PprOrigin = resp.data.PprOrigin;
          this.palletProcess.PprOriginID = resp.data.PprOriginID;
          this.palletProcess.CumId = resp.data.CumId;
          this.palletProcess.CumNombre = resp.data.CumNombre;
          this.palletProcess.TypeTurn = resp.data.TypeTurn;

          this.palletProcess.FfsDateSampling = resp.data.FfsDateSampling;//jmr
         

          //samir ingrese peso bruto
				  this.TypePallet = this.TypePalletItems.find( x => x.DedValue == item.TypePalletMate);			 
				  this.palletProcess.PprWeightGross = parseFloat(resp.data.LlpWeightOriginalGross);

				  //this.inputWeightGross(0);  

            _sParameter.search({ PrmName: "pesoJabaCongelado"}, this.$fun.getUserID()).then((r) => {
              this.palletProcess.PprWeightJaba  = r.data.PrmValue;
            });
      

          _sSamplingLocationService
          .list(this.palletProcess, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200)
            {
              this.samplingLocation = resp.data;
            }
          })
          this.openModalSendProcess();
          val = "";
        })
        
      }
    },

    

  /*   intitialize() {
		_sPalletProcessService
        .list(this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.pallets = resp.data;
            console.log("response ", resp.data);
          }
        });
    }, */

    openModalSendProcess()
    {
      this.viewModalSendProccess = true;
      
    },

    openModal(items) {
      this.TypeCultive = items.TypeCultive;
      this.pallet = items;
      this.openDialog = true;
      /* this.PalletNro = items.TypePallet;
				this.TypeFrozenCategory = items.TypeFrozenCategory; */
    },

    /* WeighMaterial() {
      this.dialogWeigh = true;
    }, */

    /* ModalBrix() {
      this.BrixGrade = true;
    }, */

    closeModal() {
      this.openDialog = false;
      this.getPalletProcess();
      /* this.intitialize(); */
      this.$refs.crudpallets.refresh();
    },

    viewDetail(item) {
      this.dialogDetail = true;
      this.detail = item;
    },
  },

  created() {
    /* this.intitialize(); */
    this.getPalletProcess();
    //samir
    this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
  },
};
</script>

<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>